import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Card from "./Card"

const Cards = ({ items, hideLastItemOnMobile = false, hasMore, onClick }) => {
  return (
    <div className="container">
      <div className="flex flex-wrap -mx-3 lg:-mx-6">
        {items.map(item => (
          <div
            className={classNames("w-full sm:w-1/2 lg:w-1/3 p-3 md:p-6", {
              "last:hidden lg:last:block": hideLastItemOnMobile,
            })}
            key={item.id}
          >
            <Card {...item} />
          </div>
        ))}
      </div>
        {hasMore &&
        <div className="w-full flex items-center justify-center">
          <button onClick={onClick} className="inline-block p-2 font-medium leading-snug border border-blue-700 text-base rounded-md text-blue-600 bg-transparent hover:bg-blue-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
            Load More
          </button>
        </div>
        }
    </div>
  )
}

Cards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Cards
