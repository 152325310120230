import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Card = props => {
  const { name, slug, summary, thumbnail } = props

  return (
    <div className="prose bg-white h-full rounded-md overflow-hidden group">
      <Link to={`/${slug}/`}>
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
          <Img
            className="h-40 rounded-lg"
            fluid={thumbnail.localFile.childImageSharp.fluid}
            alt={name}
          />
        </div>
        <div className="py-4 sm:py-5">
          <h1 className="sm:text-lg text-blue-800 font-semibold">
            {name}
          </h1>
          <p className="text-sm sm:text-base text-gray-700">{summary}</p>
        </div>
      </Link>
    </div>
  )
}

Card.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    localFile: PropTypes.object,
  }),
}

export default Card

export const query = graphql`
  fragment PostCard on ContentfulPost {
    id
    name
    author{
      name
    }
    slug
    summary
    thumbnail {
      localFile {
        childImageSharp {
          fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
  fragment RecipeCard on ContentfulRecipe{
    steps
    ingredients
    tools
    type
    prepTime
    cookTime
    servings
  }
`
