import React from "react"
import styled from "styled-components"

const HeroDiv = styled.div`
    background: url(/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
`

const HeroHeader = styled.h1`
  font-family: "Libre Caslon Text";
  .highlight-container,
  .highlight {
    position: relative;
  }

  .highlight-container {
    display: inline-block;
  }
  .highlight-container:before {
    content: " ";
    display: block;
    height: 90%;
    width: 90%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: #6ac75e4d;
    transform: rotate(2deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
  }
`

const Hero = ({data}) => {
return (
  <div className="container max-w-screen-lg mx-auto text-center mt-12">
         <section className="bg-gray-300 rounded-lg text-gray-700 body-font">
            <HeroDiv className="container px-8 py-32 mx-auto lg:px-4">
                <div className="flex flex-col w-full text-left lg:text-center">
                    {/* <h2 className="mb-1 text-xs font-semibold tracking-widest text-blue-600 uppercase title-font">Vegan Blender</h2> */}
                    <HeroHeader className="mb-2 text-4xl leading-none sm:text-8xl font-bold tracking-tighter text-blue-800 sm:text-5xl title-font">
                        <span className="highlight-container"><em className="highlight">Vegan</em></span> Blender Guides and Recipes  
                    </HeroHeader>
                    <p className="mx-auto text-base font-xl leading-relaxed text-gray-700 lg:w-2/3">We curate plant-based recipes that use our favorite kitchen tool. Our vegan recipes save you hours of time and tons of money by handling all the experimentation with technique and ingredients. </p>
                </div>
            </HeroDiv>
        </section>
  </div>
)
}

export default Hero